"use strict";

angular.module("informaApp")
    .service("PhasesBarHelper", ["d3", function (d3) {
        var result = {};

        result.createPhasesProgressBar = function (params) {
            var options = {
                container: null,
                x: 0,
                y: 0,
                width: 100,
                height: 10,
                source: {
                    total: 4,
                    phases: [
                        {offset: 0, value: 1},
                        {offset: 1, value: 1},
                        {offset: null, value: null},
                        {offset: 3, value: 1},
                    ]
                },
                progressBarColors: ["#EFBF29", "#E50858", "#5D3B82", "#61697D"],
                progressBarColorsHovered: ["#FFE330", "#FF0368", "#9961D6", "#A4B2D4"],
                defsElement: null,
                patternId: "dash-fill",
                phaseMouseMove: null,
                phaseMouseOut: null,
                hideNoDataBar: false
            }

            options = _.merge(options, params);

            if (options.defsElement && !options.hideNoDataBar) {
                addPattern(options.defsElement);
            }

            var progress = options.container.append("g");

            progress.attr("class", "progress-container")
                .attr("transform", "translate(" + options.x + "," + options.y + ")");

            function appendRect(color, hoveredColor, width, className, x, phaseName, phaseValue) {
                var item = progress.append("rect")
                    .attr("fill", color)
                    .attr("width", width)
                    .attr("height", options.height + "px")
                    .attr("class", className)
                    .attr("stroke", "white")
                    .attr("stroke-width", "0.5px")
                    .attr("x", x)
                    .on("mousemove", function (d) {
                        if (options.phaseMouseMove){
                            d3.select(this).attr("fill", hoveredColor);
                            document.body.style.cursor = 'pointer';

                            options.phaseMouseMove({ name: phaseName, duration: phaseValue, durationToString: (d ? d.durationToString : options.durationToString)} );
                        }
                    })
                    .on("mouseout", function (d) {
                        if (options.phaseMouseMove) {
                            d3.select(this).attr("fill", color);
                            document.body.style.cursor = 'default';
                        }

                        if (options.phaseMouseOut){
                            options.phaseMouseOut();
                        }
                    });
            }

            var stepLength = options.width / options.source.total;

            var fillUndefinedBar = "url(#" + options.patternId + ")";

            if (!options.hideNoDataBar) {
                appendRect(fillUndefinedBar, fillUndefinedBar, options.width, "undefined-bar", "0px", "No Data", getNoDataDuration(options.source.total, options.source.phases));
            }

            for (var i = 0; i < options.source.phases.length; i++) {
                var bar = options.source.phases[i];

                if (bar.value == null || bar.offset == null) continue;

                var barWidth = (bar.value * stepLength) + "px";
                var offsetX = (bar.offset * stepLength) + "px";

                appendRect(options.progressBarColors[i], options.progressBarColorsHovered[i], barWidth, "bar" + (i + 1), offsetX, bar.name, bar.value);
            }

            function addPattern(defs) {
                if (defs.select("#" + options.patternId)[0][0]) {
                    return;
                }

                defs.append("pattern")
                    .attr({
                        id: options.patternId,
                        width: "4",
                        height: "4",
                        patternUnits: "userSpaceOnUse",
                        patternTransform: "rotate(60)"
                    })
                    .append("rect")
                    .attr({width: "1", height: "4", transform: "translate(0,0)", fill: "#AAA"});
            }
        }

        function getNoDataDuration(total, phases) {
            var phasesWithData = phases.filter(function(bar) {
                return bar.value != null && bar.offset != null;
            });

            var sum = _.sum(phasesWithData.map(function (x) {
                return x.value;
            }));

            return total - sum;
        }

        return result;
    }]);